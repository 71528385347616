import { useMemo } from "react";
import { useAuth } from "@reasongcp/react-fire-sub";

import useCurrentMemberDoc from "./useCurrentMemberDoc";

const useIsAdmin = () => {
  const user = useAuth();
  const memberDoc = useCurrentMemberDoc();
  const isAdmin = useMemo(() => {
    if (!memberDoc || !user) return false;
    return Boolean(memberDoc.roles?.includes("admin"));
  }, [memberDoc, user]);

  return { isAdmin, loaded: Boolean(memberDoc) };
};

export default useIsAdmin;
