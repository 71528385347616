import React, {
  FC,
  useMemo,
  useState,
  useContext,
  PropsWithChildren,
} from "react";

export const RegistrationContext = React.createContext({
  email: "",
  setEmail: (email: string) => {},
});

export const useRegistrationContext = () => {
  return useContext(RegistrationContext);
};

// Provides shared context between pages as necessary for user registration.
const RegistrationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [email, setEmail] = useState("");

  const registrationDetails = useMemo(() => {
    return { email, setEmail };
  }, [email, setEmail]);

  return (
    <RegistrationContext.Provider value={registrationDetails}>
      {children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationProvider;
