import React, { FC, PropsWithChildren } from "react";

import { KnownFlags } from ".";
// eslint-disable-next-line
import useEnvironmentFeatureFlags from "../../../hooks/useEnvironmentFeatureFlags";

export interface FeatureFlagBoundaryProps {
  feature: KnownFlags;
}

const getOverride = (featureName: string) => {
  const override = window.localStorage.getItem(featureName);
  const globalOverride = window.localStorage.getItem("ENABLE_ALL_FEATURES");

  return [override, globalOverride].some((o) => o === "TRUE");
};

const FeatureFlagBoundary: FC<PropsWithChildren<FeatureFlagBoundaryProps>> = ({
  children,
  feature,
}) => {
  const flags = useEnvironmentFeatureFlags();
  if (flags.includes(feature) || getOverride(feature)) {
    return (
      <>
        {children}
      </>
    );
  }

  return <></>;
};

export default FeatureFlagBoundary;
