import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Card, Col, Container, Row } from "react-bootstrap";

import { FormSwitch } from "../Form";
import LegalFooter from "./LegalFooter";
import FormLinkHeader from "./FormLinkHeader";
import colors from "../../../constants/colors";
import useAuthLinkForPath from "../../../hooks/useAuthLinkForPath";

const StyledCard = styled(Card)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledBackground = styled.div`
  background: ${colors.tsBlueGray};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FormLinkWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledBackground>
      <div>
        <FormLinkHeader />
        <Container>
          <Row>
            <Col>
              <StyledCard>
                <Card.Body>{children}</Card.Body>
              </StyledCard>
            </Col>
          </Row>
        </Container>
      </div>
      <LegalFooter />
    </StyledBackground>
  );
};

const FormLink: FC = () => {
  const { data, loading } = useAuthLinkForPath();

  if (loading || !data) return null;
  const state = data?.formAuthLink?.form?.stateAbbr || "";
  const formType = data?.formAuthLink?.form?.shortName || "";

  return (
    <FormLinkWrapper>
      <FormSwitch
        state={state}
        formType={formType}
      />
    </FormLinkWrapper>
  );
};

export default FormLink;
