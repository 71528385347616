const rollMappings = {
  user: "user",
  admin: "admin",
  manager: "manager",
  billing: "billing",
  preparer: "preparer",
  signer: "signer",
} as const;

export type UserRole = keyof typeof rollMappings;

export default rollMappings;
