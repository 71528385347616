/* eslint-disable max-len */
import Button from "react-bootstrap/Button";
import { Field, Form, Formik } from "formik";
import { FC, useCallback, useState } from "react";
import { useSUBContext } from "@reasongcp/react-fire-sub";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";

import UnauthenticatedWrapper from "../../shared/UnauthenticatedWrapper";

interface ResetPasswordPayload {
  email: string;
}

const AlreadySent: FC = () => {
  const formHeader = "Email Sent";
  const formInstructions = `If there is an account associated with your email you will receive a
            password reset link in your inbox.`;
  return (
    <UnauthenticatedWrapper
      formHeader={formHeader}
      formInstructions={formInstructions}
    >
      <Button className="w-100 text-uppercase rounded-1 mt-2 teal-btn">
        <Link
          to="/login"
          className="text-white text-decoration-none stretched-link"
        >
          Back to login
        </Link>
      </Button>
    </UnauthenticatedWrapper>
  );
};

const ResetPasswordForm: FC = () => {
  const { auth } = useSUBContext();
  const [alreadySent, setAlreadySent] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async ({ email }: ResetPasswordPayload) => {
      if (!auth) return;
      await sendPasswordResetEmail(auth, email);
      setAlreadySent(true);
    },
    [auth],
  );

  const formHeader = "Reset Password";
  const formInstructions =
    "Enter the email associated with your account and an instruction email will be sent to reset your password.";

  return !alreadySent ? (
    <UnauthenticatedWrapper
      formHeader={formHeader}
      formInstructions={formInstructions}
    >
      <Formik<ResetPasswordPayload>
        initialValues={{ email: "" }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form
            id="form-forgot-box"
            autoComplete="off"
          >
            <div className="mb-3">
              <label
                htmlFor="email"
                className="form-label"
              >
                Email Address
              </label>
              <Field
                name="email"
                type="email"
                className="form-control bg-transparent"
                placeholder="Email Address"
              />
            </div>
            <Button
              type="submit"
              className="text-uppercase w-100 teal-btn text-white"
            >
              Send Instructions
            </Button>
          </Form>
        )}
      </Formik>
    </UnauthenticatedWrapper>
  ) : (
    <AlreadySent />
  );
};

export { ResetPasswordForm };
