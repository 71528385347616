import React, { FC, useCallback } from "react";

import WhenInView from "../WhenInView";
import StatusChange from "./StatusChange";
import ClientSigned from "./ClientSigned";
import AssignedToMe from "./AssignedToMe";
import { Notification } from "../../../../types/notifications";

export interface NotificationDetailsProps {
  notification: Notification;
  onVisible: (id: number) => void;
}

type DescriptionProps = Pick<NotificationDetailsProps, "notification">;

const eventTypeMap: Record<string, FC<DescriptionProps>>= {
  FORM_STATUS_CHANGED: StatusChange,
  FORM_SIGNED: ClientSigned,
  ASSIGNED_TO_ME: AssignedToMe,
};

const Description: FC<DescriptionProps> = ({
  notification,
}) => {
  const { eventType } = notification;
  const Component = eventTypeMap[eventType];
  if (!Component) {
    return null;
  }
  return <Component notification={notification} />;
};

const NotificationDetails: FC<NotificationDetailsProps> = ({
  onVisible,
  notification,
}) => {
  const { id, viewed } = notification;
  const handleView = useCallback(() => {
    if (!viewed) {
      onVisible(id);
    }
  }, [id, onVisible, viewed]);

  return (
    <div>
      <Description notification={notification} />
      <WhenInView onVisibilityChange={handleView} />
    </div>
  );
};

export default NotificationDetails;
