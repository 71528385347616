import { shadeColor } from "@taxscribe/ui";

// TODO: Examine a bunch of these one use colors for similar colors.
const baseColors = {
  tsPrimary: "#37474f",
  tsYellow: "#ffc107",
  tsTeal: "#00b1ba",
  tsLightBlue: "#e7eef1",
  tsCyan: "#0dcaf0",
  tsTealAlt: "#287088",
  tsPurple: "#334171",
  tsPurpleAlt: "#8B50A5",
  tsGreen: "#5CB85C",
  tsGreenAlt: "#12663f",
  tsBlueGray: "#f1f3f7",
  tsPink: "#f9e1e9",
  tsFadedPurple: "#e1d1e5",
  tsLightGray: "#fdfdfd",
  tsRed: "#d9534f",
  tsGray: "#f7f7f7",
};

type BaseColors = typeof baseColors;
type DarkColors = {
  [Property in keyof typeof baseColors as `${Property}Dark`]: string;
};
type LightColors = {
  [Property in keyof typeof baseColors as `${Property}Light`]: string;
};

export interface AppColors extends BaseColors, DarkColors, LightColors {}

const generatedColors = Object.entries(baseColors).reduce((acc, [key, hex]) => {
  const lightKey = `${key}Light`;
  const darkKey = `${key}Dark`;
  const lightHex = shadeColor(hex, 75);
  const darkHex = shadeColor(hex, -75);

  return {
    ...acc,
    [key]: hex,
    [darkKey]: darkHex,
    [lightKey]: lightHex,
  };
}, {}) as AppColors;

// Generated colors can be tweaked here.
const colorOverrides: Partial<AppColors> = {};

const colors = { ...generatedColors, ...colorOverrides };
export type ColorKey = keyof AppColors;
export default colors;
