import { gql, useLazyQuery } from "@apollo/client";
import { Notification } from "../types/notifications";

export const GET_MY_NOTIFICATIONS = gql`
  query GetMyNotifications(
    $limit: Int,
    $offset: Int,
    $viewed: String,
  ){
    me {
      email
      unreadNotifications
      notifications(
        limit: $limit,
        offset: $offset,
        viewed: $viewed,
      ) {
        ... on FormEventNotification {
          id
          viewed
          organizationId
          userId
          createdAt
          updatedAt
          eventType
          metadata
          accountNumberPrimary
          ddbKey
          businessName
        }
      }
    }
  }
`;

export interface GetMyNotificationsResults {
  me: {
    email: string;
    unreadNotifications: number | null;
    notifications: Notification[] | null;
  } | null;
}

export interface UseGetMyNotificationsArgs {
  viewed?: "viewed" | "unviewed";
  limit?: number;
  offset?: number;
}

const useGetMyNotificationsLazy = (variables?: UseGetMyNotificationsArgs) => {
  const [query, queryResults] = useLazyQuery<GetMyNotificationsResults>(
    GET_MY_NOTIFICATIONS,
    { variables, fetchPolicy: "cache-first" },
  );
  const { data, previousData } = queryResults;
  const unreadCount = (data || previousData)?.me?.unreadNotifications || 0;
  const loadedCount = (data || previousData)?.me?.notifications?.length || 0;

  return {
    query,
    loadedCount,
    unreadCount,
    queryResults,
  };
};

export default useGetMyNotificationsLazy;
