import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useJurisdictionContext } from "@taxscribe/ui";

import AppNavbar from "../../shared/AppNavbar";
import formPageMap, { standardizeKey } from "../../shared/FormPageMap";
import SectionContent from "../../shared/SectionContent";

export interface FormPageProps {
  readOnly?: boolean;
}

export interface FormSwitchProps {
  state: string;
  formType: string;
  canSign?: boolean;
}

export const FormSwitch: FC<FormSwitchProps> = ({
  state,
  formType,
}) => {
  const stateForms = formPageMap[standardizeKey(state)];
  const FormPage = stateForms?.[standardizeKey(formType)];
  if (!FormPage) {
    console.error("Unable to determine form page for:", state, formType);
    return <div>An error occurred</div>;
  }
  return <FormPage />;
};

const FormSwitchWrapper: FC = () => {
  const { name } = useJurisdictionContext();
  const { state = "", formType = "" } = useParams();

  return (
    <>
      <AppNavbar pageTitle={`${name}`}/>
      <SectionContent>
        <FormSwitch
          state={state}
          formType={formType}
        />
      </SectionContent>
    </>
  );
};

export default FormSwitchWrapper;
