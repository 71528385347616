import { FC } from "react";
import { IMaskInput } from "react-imask";
import FormikText, { FormikTextProps } from "./FormikText";

const PhoneMask: FC = (props) => (
  <IMaskInput
    mask="(000)000-0000"
    {...props}
  />
);

const FormikPhone: FC<FormikTextProps> = (props) => {
  return (
    <FormikText
      {...props}
      as={PhoneMask}
    />
  );
};

export default FormikPhone;
