import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import useIsVisible from "../../../hooks/useIsVisible";

export interface WhenInViewProps {
  onVisibilityChange: () => void;
}

const TinySpan = styled.span`
  height: 1px;
`;

const WhenInView: FC<WhenInViewProps> = ({
  onVisibilityChange,
}) => {
  const bottomItemRef = useRef(null);
  const isVisible = useIsVisible(bottomItemRef);

  useEffect(() => {
    if (isVisible) {
      onVisibilityChange();
    }
  }, [isVisible, onVisibilityChange]);

  return (
    <TinySpan ref={bottomItemRef}/>
  );
};

export default WhenInView;
