import { StateAbbr } from "@taxscribe/record-types";
import { lowerCase } from "lodash";
import { FC, lazy } from "react";

const NCE500Page = lazy(() => import("../../pages/Form/NCE500"));
const TXExtensionPage = lazy(() => import("../../pages/Form/TXExtension"));
const FLExtensionPage = lazy(() => import("../../pages/Form/FLExtension"));
const IndividualPT50APage = lazy(
  () => import("../../pages/Form/PT50A/IndividualPT50A"),
);
const IndividualPT50MPage = lazy(
  () => import("../../pages/Form/PT50M/IndividualPT50M"),
);
const GAHomesteadExemptionPage = lazy(
  () => import("../../pages/Form/GAHomesteadExemption"),
);
const LAHomesteadExemptionPage = lazy(
  () => import("../../pages/Form/LAHomesteadExemption"),
);
const LAT5Page = lazy(() => import("../../pages/Form/LAT5"));
const MIExemptionPage = lazy(() => import("../../pages/Form/MIExemption"));
const MIListingPage = lazy(() => import("../../pages/Form/MIListing"));
const NCExtensionPage = lazy(() => import("../../pages/Form/NCExtension"));
const NCIPPListingPage = lazy(() => import("../../pages/Form/NCIPPListing"));
const NCListingPage = lazy(() => import("../../pages/Form/NCListing"));
const PT50APage = lazy(() => import("../../pages/Form/PT50A"));
const PT50MPage = lazy(() => import("../../pages/Form/PT50M"));
const PT50PPage = lazy(() => import("../../pages/Form/PT50P"));
const PT50PFPage = lazy(() => import("../../pages/Form/PT50PF"));
const Rendition144Page = lazy(() => import("../../pages/Form/Rendition144"));
const FlDR405Page = lazy(() => import("../../pages/Form/FlDR405"));
const AlADV40Page = lazy(() => import("../../pages/Form/ADV40"));
const MsRendition73033Page = lazy(
  () => import("../../pages/Form/MSRendition73033"),
);
const ArA6RenditionPage = lazy(() => import("../../pages/Form/ARA6Rendition"));
const TnScheduleBPage = lazy(() => import("../../pages/Form/TNScheduleB"));

export const standardizeKey = lowerCase;
const standardizeKeys = (obj: Record<string, FC>) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [standardizeKey(key)]: value,
    };
  }, {});

const rawFormPageMap: Partial<
  Record<Lowercase<StateAbbr>, Record<string, FC>>
> = {
  nc: {
    "listing": NCListingPage,
    "extension": NCExtensionPage,
    "ipp-listing": NCIPPListingPage,
    "e500": NCE500Page,
  },
  mi: {
    "listing": MIListingPage,
    "exemption": MIExemptionPage,
  },
  la: {
    "report": LAT5Page,
    "homestead-exemption": LAHomesteadExemptionPage,
  },
  ga: {
    "homestead-exemption": GAHomesteadExemptionPage,
    "pt50p": PT50PPage,
    "pt50a": PT50APage,
    "pt50m": PT50MPage,
    "pt50pf": PT50PFPage,
    "individual-pt50a": IndividualPT50APage,
    "individual-pt50m": IndividualPT50MPage,
  },
  tx: {
    "extension": TXExtensionPage,
    "rendition-144": Rendition144Page,
  },
  fl: {
    "extension": FLExtensionPage,
    "dr405": FlDR405Page,
  },
  al: {
    "adv40": AlADV40Page,
  },
  ms: {
    "rendition-73-033": MsRendition73033Page,
  },
  ar: {
    "a-6-Rendition": ArA6RenditionPage,
  },
  tn: {
    "schedule-b": TnScheduleBPage,
  },
};

const formPageMap: Record<string, Record<string, FC>> = Object.entries(
  rawFormPageMap,
).reduce((acc, [key, value]) => {
  return {
    ...acc,
    [key]: standardizeKeys(value),
  };
}, {});

export default formPageMap;
