import React, { FC, PropsWithChildren, useMemo, useState } from "react";

import MessageDrawerContext, { DrawerOptions } from "./MessageDrawerContext";

const defaultState: DrawerOptions = {
  title: null,
  formUuid: null,
};

const MessageDrawerContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const value = useMemo(() => {
    return {
      ...state,
      viewFormMessages: setState,
    };
  }, [state, setState]);

  return (
    <MessageDrawerContext.Provider value={value}>
      {children}
    </MessageDrawerContext.Provider>
  );
};

export default MessageDrawerContextProvider;
