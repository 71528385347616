import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useUserDoc } from "@reasongcp/react-fire-sub";
import {
  CreditCountData,
  GET_CREDIT_COUNT,
  GetCreditCountReturn,
} from "@taxscribe/gql-queries";

const useCreditCount = () => {
  const userDoc = useUserDoc();
  const uuid = userDoc?.currentOrganization || "";
  const [runQuery, { loading, error, data, called }] =
    useLazyQuery<GetCreditCountReturn>(GET_CREDIT_COUNT, {
      variables: { uuid },
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    if (uuid) runQuery();
  }, [uuid, runQuery]);

  const creditCount: Partial<CreditCountData> =
    data?.organization.creditCount || {};

  return {
    data,
    error,
    called,
    ...creditCount,
    loading: loading || !uuid,
  };
};

export default useCreditCount;
