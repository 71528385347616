import { useMemo } from "react";
import { UserRole } from "../constants/roleMappings";
import useCurrentMemberDoc from "./useCurrentMemberDoc";
import useIsAdmin from "./useIsAdmin";

const useIsAllowed = (roles: UserRole[]) => {
  const memberDoc = useCurrentMemberDoc();
  const { isAdmin, loaded: adminLoaded } = useIsAdmin();
  const isAllowed = useMemo(() => {
    return roles.some((role) => memberDoc?.roles?.includes(role));
  }, [memberDoc, roles]);

  return {
    loaded: Boolean(adminLoaded && memberDoc),
    isAllowed: isAdmin || isAllowed,
  };
};

export default useIsAllowed;
