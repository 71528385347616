import React, { FC } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

import config from "../../../config";

const LegalFooterWrapper = styled.div`
  width: 100vw;
`;

const LegalFooter: FC = () => {
  const proMarketingPage = `${config.marketingUri}professional/`;

  return (
    <LegalFooterWrapper>
      <Container>
        <Row>
          <Col className="text-center">
            {/* eslint-disable max-len */}
            <p>
              By using this application, you agree to the{" "}
              <a
                className="fw-bold text-decoration-none text-pro-teal teal-links"
                href={`${proMarketingPage}termsAndConditions`}
              >
                Terms and Conditions
              </a>
              , the{" "}
              <a
                className="fw-bold text-decoration-none text-pro-teal teal-links"
                href={`${proMarketingPage}privacyPolicy`}
              >
                Privacy Policy
              </a>
              , and the{" "}
              <a
                className="fw-bold text-decoration-none text-pro-teal teal-links"
                href={`${proMarketingPage}eula`}
              >
                EULA
              </a>
              .
            </p>
            {/* eslint-enable max-len */}
          </Col>
        </Row>
      </Container>
    </LegalFooterWrapper>
  );
};

export default LegalFooter;
