import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import {
  NotificationWrapper,
  NotificationIconWrapper,
  NotificationDescriptionProps,
} from "./shared";

const ClientSigned: FC<NotificationDescriptionProps> = ({ notification }) => {
  const { accountNumberPrimary, businessName, viewed } = notification;
  const bestDisplayName = accountNumberPrimary || businessName;
  return (
    <NotificationWrapper viewed={viewed}>
      <>
        <NotificationIconWrapper>
          <FontAwesomeIcon icon={faCircleExclamation} />
        </NotificationIconWrapper>
        Form {bestDisplayName} has been signed
      </>
    </NotificationWrapper>
  );
};

export default ClientSigned;
