import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

import {
  NotificationWrapper,
  NotificationIconWrapper,
  NotificationDescriptionProps,
} from "./shared";

const StatusChange: FC<NotificationDescriptionProps> = ({ notification }) => {
  const { accountNumberPrimary, businessName, viewed } = notification;
  const bestDisplayName = accountNumberPrimary || businessName;
  const status = notification.metadata?.status || "Updated";

  return (
    <NotificationWrapper viewed={viewed}>
      <>
        <NotificationIconWrapper>
          <FontAwesomeIcon icon={faCircleExclamation} />
        </NotificationIconWrapper>
        Form {bestDisplayName} has been {status}
      </>
    </NotificationWrapper>
  );
};

export default StatusChange;
