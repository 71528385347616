import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  useAuth,
  useGetMyOrganizations,
  useSUBContext,
  useUserDoc,
} from "@reasongcp/react-fire-sub";

import config from "../../config";
import { doc, updateDoc } from "firebase/firestore";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($name: String!, $adminUid: String!) {
    createOrganization(name: $name, adminUid: $adminUid) {
      name
      createdAt
      memberUids
    }
  }
`;

const OrganizationWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { firestore } = useSUBContext();
  const [organizations, setOrganizations] = useState<any[] | null>(null);
  const client = useApolloClientWithAuth(config.fireSubApi);
  const user = useAuth();
  const userDoc = useUserDoc();

  const [mutation] = useMutation(CREATE_ORGANIZATION, {
    client,
  });

  const getMyOrganizations = useGetMyOrganizations();

  // If an existing user signs into professional for the first time and does
  // not have any associated organizations, we are creating one for them here.
  useEffect(() => {
    if (!user || !userDoc) return;
    getMyOrganizations().then(async (results) => {
      if (!results) return;

      if (results.length === 0) {
        const name = userDoc?.displayName || user.displayName || user.email;
        const variables = { name, adminUid: user?.uid };
        await mutation({ variables });
        const newResults = await getMyOrganizations();
        return await setOrganizations(newResults);
      }

      await setOrganizations(results);
    });
  }, [user, userDoc, mutation, getMyOrganizations, setOrganizations]);

  useEffect(() => {
    if (!firestore || !user || !organizations?.length) return;

    if (userDoc && !userDoc.currentOrganization) {
      const docRef = doc(firestore, "/users", user.uid);
      updateDoc(docRef, { currentOrganization: organizations[0].uid });
    }
  }, [userDoc, organizations, firestore, user]);

  if (!organizations) return null;

  return <>{children}</>;
};

export default OrganizationWrapper;
