import { FormDataKey, FormData } from ".";
import { checkPresence, validateEmail } from "../../../lib/validators";

const checkPasswordsMatch = (values: FormData) => {
  if (values.password !== values.passwordConfirmation) {
    return { passwordConfirmation: "* Must Match Password" };
  }
  return {};
};

const requiredFields: FormDataKey[] = [
  "name",
  "email",
  "displayName",
  "password",
  "passwordConfirmation",
];

const validations = [
  validateEmail,
  ...requiredFields.map(checkPresence),
  checkPasswordsMatch,
];

const validate = (values: FormData) => {
  const errors = validations.reduce((acc, validation) => {
    return {
      ...acc,
      ...validation(values),
    };
  }, {} as Partial<FormData>);

  return errors;
};

export default validate;
