import { useEffect, useState } from "react";
import { doc, DocumentData, getDoc } from "firebase/firestore";
import { useAuth, useSUBContext } from "@reasongcp/react-fire-sub";

import useObserveUserDoc from "./useObservUserDoc";

const useCurrentMemberDoc = () => {
  const { firestore } = useSUBContext();
  const [memberDoc, setMemberDoc] = useState<DocumentData | null>();
  const user = useAuth();
  const userDoc = useObserveUserDoc();
  const currentOrganization = userDoc?.currentOrganization || null;

  useEffect(() => {
    if (!currentOrganization || !firestore || !user) return;
    const orgDoc = doc(firestore, "/organizations", currentOrganization);
    const myDoc = doc(orgDoc, "/members", user.uid);
    getDoc(myDoc).then((me) => {
      if (!me.exists()) return;
      setMemberDoc(me.data());
    });
  }, [user, firestore, currentOrganization]);

  return memberDoc;
};

export default useCurrentMemberDoc;
