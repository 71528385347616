import React, { FC, PropsWithChildren } from "react";
import { Container } from "react-bootstrap";

const SectionContent: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container
      fluid
      id="main-content"
      className="p-3 bg-pro-light-grey"
    >
      {children}
    </Container>
  );
};

export default SectionContent;
