import { useAuth } from "@reasongcp/react-fire-sub";
import React, { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";

const sidebarWidth = 280;
const AppLayout = styled.div`
  @media (min-width: 992px) {
    /* Sidebar */
    display: flex;

    & > #sidebar {
      width: ${sidebarWidth}px !important;
      height: 100vh;
      overflow-y: auto;
      -ms-overflow-style: none;
      flex-direction: column;
      align-items: flex-start;
    }
    & > #sidebar div {
      width: 100%;
    }
    main {
      max-width: calc(100vw - ${sidebarWidth}px);
      margin-left: auto;
    }
  }
`;

const VerificationBoundary: FC<PropsWithChildren> = ({ children }) => {
  const user = useAuth();

  if (user && !user.emailVerified) {
    return <Navigate to="/register/verify-email" />;
  }

  return (
    <>
      <AppLayout>{children}</AppLayout>
    </>
  );
};

export default VerificationBoundary;
