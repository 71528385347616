import React, { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "../../constants/roleMappings";
import useIsAllowed from "../../hooks/useIsAllowed";

export interface UserRoleBoundaryProps {
  redirectTo?: string;
  roles?: UserRole[];
  // allowAll is an explicit override to be fully permissive in what is
  // displayed. This is an implementation convenience to allow wrapping
  // components to maintain less logic and a simple interface.
  allowAll?: boolean;
}

const EmptyContent: FC<Pick<UserRoleBoundaryProps, "redirectTo">> = ({
  redirectTo,
}) => {
  if (redirectTo) return <Navigate to={redirectTo} />;
  return null;
};

const UserRoleBoundary: FC<PropsWithChildren<UserRoleBoundaryProps>> = ({
  allowAll,
  children,
  roles = [],
  redirectTo,
}) => {
  const { isAllowed, loaded } = useIsAllowed(roles);

  if (!loaded) return null;
  if (!isAllowed && !allowAll) return <EmptyContent redirectTo={redirectTo} />;
  return <>{children}</>;
};

export default UserRoleBoundary;
