import { noop } from "lodash";
import { createContext } from "react";

export interface DrawerOptions {
  title: string | null;
  formUuid: string | null;
}

export interface MessageDrawerContextState extends DrawerOptions {
  viewFormMessages: (opts: DrawerOptions) => void;
}

const MessageDrawerContext = createContext<MessageDrawerContextState>({
  title: null,
  formUuid: null,
  viewFormMessages: noop,
});

export default MessageDrawerContext;
