import { useUserDoc } from "@reasongcp/react-fire-sub";
import { useObserveDocumentAttribute } from "@taxscribe/ui";
import React, { FC, useEffect, PropsWithChildren } from "react";
import { Navbar } from "react-bootstrap";

import useCreditCount from "../../hooks/useCreditCount";

interface WrapperProps {
  creditCount?: number | null;
}

const creditClass = "text-capitalize ls-1 fw-bold fs-4 py-lg-3";
const noCreditClass = "text-capitalize ls-1 fw-bold fs-4 py-lg-3 text-danger";

const getClassName = (creditCount?: number | null) => {
  if (creditCount === undefined || creditCount === null || creditCount > 0) {
    return creditClass;
  }

  return noCreditClass;
};

const AutoPayNotification: FC = () => {
  const userDoc = useUserDoc();
  const [autoPurchase] = useObserveDocumentAttribute<boolean>({
    defaultValue: false,
    attributeKey: "autoPurchase",
    documentPath: `organizations/${userDoc?.currentOrganization}`,
  });

  if (!autoPurchase) return null;
  return (
    <div>
      <span className={creditClass}>
        Auto Pay Enabled: {String(autoPurchase)}{" "}
      </span>
    </div>
  );
};

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({
  children,
  creditCount,
}) => {
  const className = getClassName(creditCount);

  return (
    <Navbar.Brand>
      <div>
        <span className={className}>{children}</span>
        {creditCount}
      </div>
      <AutoPayNotification />
    </Navbar.Brand>
  );
};

const AvailableCreditsHeader: FC = () => {
  const { available, called, loading, error } = useCreditCount();

  useEffect(() => {
    if (error) console.error(error);
  }, [error]);

  if (!called || loading) {
    return <Wrapper>Fetching Credits...</Wrapper>;
  }

  if (error) {
    return <Wrapper>Error Retrieving Credits</Wrapper>;
  }

  return <Wrapper creditCount={available}>Available Credits: </Wrapper>;
};

export default AvailableCreditsHeader;
