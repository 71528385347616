import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import WhenInView, { WhenInViewProps } from "./WhenInView";
import colors from "../../../constants/colors";
import useGetMyNotificationsLazy from "../../../hooks/useMyNotificationsLazy";
import NotificationDetails from "./NotificationDetails";
import useReadMyNotifications from "../../../hooks/useReadMyNotifications";

const BellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .dropdown-toggle::before {
    display: none;
  }

  svg {
    color: ${colors.tsBlueGrayLight};
    :hover {
      cursor: pointer;
    }
  }

  .dropdown-menu {
    max-height: 20vh;
    overflow-y: scroll;
  }
`;

const UnreadBubble = styled.span`
  position: absolute;
  top: 0.2rem;
  right: 0.25rem;
  height: 12px;
  min-width: 12px;
  border-radius: 0.5rem;
  border: 0.1rem solid ${colors.tsPurpleAlt};
  background-color: ${colors.tsPurpleAlt};
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: x-small;
`;

interface UnreadProps {
  unreadCount?: number | null;
}

const Unread: FC<UnreadProps> = ({ unreadCount }) => {
  if (!unreadCount) return null;
  return <UnreadBubble>{unreadCount}</UnreadBubble>;
};

interface LoaderProps {
  loading?: boolean;
  loadMore: WhenInViewProps["onVisibilityChange"];
}

const Loader: FC<LoaderProps> = ({ loading, loadMore }) => {
  if (loading) return null;
  return (
    <Dropdown.Item>
      <WhenInView onVisibilityChange={loadMore} />
    </Dropdown.Item>
  );
};

const MyNotifications: FC = () => {
  const [inView, setInView] = useState<number[]>([]);
  const [readNotifications] = useReadMyNotifications();
  const {
    query,
    loadedCount,
    unreadCount,
    queryResults: { data, previousData, called, loading },
  } = useGetMyNotificationsLazy({ limit: 10 });

  const loadMore = useCallback(() => {
    query({ variables: { limit: loadedCount + 10 } });
  }, [loadedCount, query]);

  const handleVisible = useCallback(
    (id: number) => {
      setInView((prior) => {
        if (prior.includes(id)) return prior;
        return [...prior, id];
      });
    },
    [setInView],
  );

  const handleToggle = useCallback(
    (willBeOpen: boolean) => {
      if (!willBeOpen) readNotifications({ variables: { ids: inView } });
    },
    [readNotifications, inView],
  );

  useEffect(() => {
    if (!called) query();
  }, [called, query]);

  return (
    <BellWrapper>
      <Dropdown
        drop="start"
        onToggle={handleToggle}
      >
        <Dropdown.Toggle>
          <FontAwesomeIcon icon={faBell} />
          <Unread unreadCount={unreadCount} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {(data || previousData)?.me?.notifications?.map((notification) => {
            return (
              <Dropdown.Item
                key={notification.id}
                eventKey={notification.id}
              >
                <NotificationDetails
                  onVisible={handleVisible}
                  notification={notification}
                />
              </Dropdown.Item>
            );
          }) || null}
          <Loader
            loading={loading}
            loadMore={loadMore}
          />
        </Dropdown.Menu>
      </Dropdown>
    </BellWrapper>
  );
};

export default MyNotifications;
