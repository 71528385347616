/* eslint-disable max-len */
import { useAuth } from "@reasongcp/react-fire-sub";
import React, { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../../../constants/colors";

const MainWithBackground = styled.main`
  background-color: ${colors.tsLightGray};
`;

const MainSection: FC<PropsWithChildren> = ({ children }) => {
  const user = useAuth();

  if (user && !user.emailVerified) {
    return <Navigate to="/register/verify-email" />;
  }

  return <MainWithBackground className="w-100">{children}</MainWithBackground>;
};

export default MainSection;
