import React, { FC, PropsWithChildren, useMemo } from "react";
import { FeatureFlagsContext, knownFlags, KnownFlags } from ".";
import { KnownStage } from "../../../config";

const FeatureFlagsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  // Someday this will become a GQL call.
  const state = useMemo(() => {
    const flagsByEnv: Record<KnownStage, KnownFlags[]> = {
      "qa": [
        "ELECTRONIC_SIGNATURE",
        "QB_INTEGRATION",
        "SIGNATURE_LINKS",
      ],
      "uat": [
        "SIGNATURE_LINKS",
      ],
      "production": [
        "SIGNATURE_LINKS",
      ],
      "development": [
        ...knownFlags,
      ],
    };

    return flagsByEnv;
  }, []);

  return (
    <FeatureFlagsContext.Provider value={state}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsContextProvider;
