import styled from "styled-components";
import colors from "../../../../constants/colors";
import { Notification } from "../../../../types/notifications";

export interface NotificationDetailsProps {
  notification: Notification;
  onVisible: (id: number) => void;
}

interface NotificationWrapperProps {
  viewed: boolean;
}

const getViewedColor = ({ viewed }: NotificationWrapperProps) => {
  if (viewed) {
    return colors.tsBlueGrayDark;
  }
  return colors.tsPurpleAlt;
};

export const NotificationWrapper = styled.div<NotificationWrapperProps>`
  display: flex;
  justify-content: start;
  gap: 1rem;

  && svg {
    color: ${getViewedColor};
  }
`;

export const NotificationIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export type NotificationDescriptionProps = Pick<
  NotificationDetailsProps,
  "notification"
>;
