import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

import {
  NotificationWrapper,
  NotificationIconWrapper,
  NotificationDescriptionProps,
} from "./shared";

const AssignedToMe: FC<NotificationDescriptionProps> = ({ notification }) => {
  const { accountNumberPrimary, businessName, viewed } = notification;
  const bestDisplayName = accountNumberPrimary || businessName;

  return (
    <NotificationWrapper viewed={viewed}>
      <>
        <NotificationIconWrapper>
          <FontAwesomeIcon icon={faCircleExclamation} />
        </NotificationIconWrapper>
        You have been assigned a form for {bestDisplayName}
      </>
    </NotificationWrapper>
  );
};

export default AssignedToMe;
