import React, { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import FeatureFlagBoundary from "../FeatureFlagsContext/FeatureFlagBoundary";

const QB = lazy(() => import("../../pages/QB"));
const QBAuthCallback = lazy(() => import("../../pages/QBAuthCallback"));

const QBRoutes: FC = () => {
  return (
    <FeatureFlagBoundary feature="QB_INTEGRATION">
      <Routes>
        <Route
          path="/auth"
          element={<QBAuthCallback />}
        />
        <Route
          path="/"
          element={<QB />}
        />
      </Routes>
    </FeatureFlagBoundary>
  );
};

export default QBRoutes;
