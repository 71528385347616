import React, { FC, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { SUBContextProvider } from "@reasongcp/react-fire-sub";
import { TaxbaseApolloProvider } from "@taxscribe/apollo-helpers";
import { AppToastProvider, AppToasts, toastComponents } from "@taxscribe/ui";

import config from "./config";
import AppRoutes from "./components/shared/AppRoutes";
import ModalProvider from "./components/shared/ModalProvider";
/* eslint-disable max-len */
import LoadingPlaceholder from "./components/shared/AppRoutes/LoadingPlaceholder";
import FeatureFlagsContextProvider from "./components/shared/FeatureFlagsContext/Provider";
/* eslint-enable max-len */

const App: FC = () => {
  return (
    <AppToastProvider
      defaultTimeout={5000}
      ToastComponents={toastComponents}
    >
      <SUBContextProvider
        config={config.firebaseConfig}
        authCookieName={config.authCookieName}
        emulate={config.releaseStage === "development"}
      >
        <TaxbaseApolloProvider
          credentials="include"
          uri={config.taxbaseUri}
        >
          <FeatureFlagsContextProvider>
            <ModalProvider>
              <BrowserRouter>
                <Suspense fallback={<LoadingPlaceholder />}>
                  <AppRoutes />
                </Suspense>
              </BrowserRouter>
            </ModalProvider>
          </FeatureFlagsContextProvider>
        </TaxbaseApolloProvider>
      </SUBContextProvider>
      <AppToasts />
    </AppToastProvider>
  );
};

export default App;
