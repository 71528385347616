import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Container, Navbar } from "react-bootstrap";

import TSForm from "../../../images/taxscribe-pro-logo.png";

const StyledLogo = styled.img`
  width: 120px;
  height: auto;
`;

const FormLinkHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Navbar>
      <Container>
        <Navbar.Brand href="/">
          <StyledLogo
            src={TSForm}
            className="d-inline-block align-top"
            alt="TaxScribe logo"
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default FormLinkHeader;
