import { createContext } from "react";
import { KnownStage } from "../../../config";

export const knownFlags = [
  "ELECTRONIC_SIGNATURE",
  "QB_INTEGRATION",
  "SIGNATURE_LINKS",
] as const;

export type KnownFlags = typeof knownFlags[number];

export type EnvironmentFlags = Record<KnownStage, KnownFlags[]>;

export const FeatureFlagsContext = createContext<EnvironmentFlags>({
  "qa": [],
  "uat": [],
  "production": [],
  "development": [],
});
