import { FC, PropsWithChildren } from "react";
import { Routes } from "react-router-dom";
import { UserRole } from "../../../constants/roleMappings";
import UserRoleBoundary from "../UserRoleBoundary";

export interface RestrictedRoutesProps {
  roles?: UserRole[];
  allowAll?: boolean;
  redirectTo?: string;
}

const RestrictedRoutes: FC<PropsWithChildren<RestrictedRoutesProps>> = ({
  roles,
  children,
  allowAll,
  redirectTo,
}) => {
  return (
    <UserRoleBoundary
      roles={roles}
      allowAll={allowAll}
      redirectTo={redirectTo}
    >
      <Routes>{children}</Routes>
    </UserRoleBoundary>
  );
};

export default RestrictedRoutes;
