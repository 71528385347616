import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Button } from "react-bootstrap";

import config from "../../../config";
import { useRegistrationContext } from "../../shared/RegistrationProvider";
import { useAuth } from "@reasongcp/react-fire-sub";
import { sendEmailVerification } from "firebase/auth";
import UnauthenticatedWrapper from "../../shared/UnauthenticatedWrapper";

const VerifyEmail: FC = () => {
  const user = useAuth();
  const { email } = useRegistrationContext();
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const handleResend = useCallback(() => {
    if (user) sendEmailVerification(user);
  }, [user]);

  return (
    <UnauthenticatedWrapper>
      <Container>
        <Row>
          <Col>
            <h3>Verify Email Sent</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              We sent a verification link to {email} from {config.appEmail}.
              Please check your inbox.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={handleClick}>Back to Login</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Didn't receive an email?{" "}
              <Button
                variant="link"
                onClick={handleResend}
              >
                Resend Email
              </Button>
            </p>
          </Col>
        </Row>
      </Container>
    </UnauthenticatedWrapper>
  );
};

export default VerifyEmail;
