import { useFormAuthLink } from "@taxscribe/ui";
import { useParams } from "react-router-dom";

const useAuthLinkForPath = () => {
  const { linkUuid } = useParams();
  const results = useFormAuthLink(linkUuid);

  if (!linkUuid) {
    return {
      data: null,
      loading: false,
      called: false,
      linkUuid: null,
      canSign: false,
      hasAuthLink: false,
    };
  }

  const canSign =
    results?.data?.formAuthLink?.permissions?.some(
      (p) => p.permission === "SIGN" && !p.consumed,
    ) || false;

  return {
    ...results,
    canSign,
    linkUuid,
    hasAuthLink: true,
  };
};

export default useAuthLinkForPath;
