import { useContext } from "react";
import { FeatureFlagsContext } from "../components/shared/FeatureFlagsContext";
import config from "../config";

const useEnvironmentFeatureFlags = () => {
  const env = config.releaseStage;
  const flags = useContext(FeatureFlagsContext);

  return flags[env];
};

export default useEnvironmentFeatureFlags;
