import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import Register from "../../pages/Register";
import VerifyEmail from "../../pages/VerifyEmail";
import RegistrationProvider from "../RegistrationProvider";

const RegistrationRoutes: FC = () => {
  return (
    <RegistrationProvider>
      <Routes>
        <Route
          path="/new-org"
          element={<Register />}
        />
        <Route
          path="/verify-email"
          element={<VerifyEmail />}
        />
      </Routes>
    </RegistrationProvider>
  );
};

export default RegistrationRoutes;
