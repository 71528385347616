import React, {
  FC,
  useMemo,
  useState,
  ReactNode,
  PropsWithChildren,
  useCallback,
} from "react";
import { noop } from "lodash";
import { Modal } from "react-bootstrap";

interface SetModalContentArgs {
  modalContent: ReactNode | null;
  modalSize?: string | null;
}

interface ModalContextState {
  closeModal: () => void;
  modalSize?: "xl" | "lg" | "sm";
  modalContent: ReactNode | null;
  setModalContent: (args: SetModalContentArgs) => void;
}

const initialState: ModalContextState = {
  modalSize: "lg",
  closeModal: noop,
  modalContent: null,
  setModalContent: noop,
};

const ModalContext = React.createContext(initialState);

export const useModalContext = () => React.useContext(ModalContext);

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [content, setModalContent] = useState({
    modalSize: initialState.modalSize,
    modalContent: initialState.modalContent,
  });

  const closeModal = useCallback(() => {
    setModalContent({
      modalSize: initialState.modalSize,
      modalContent: initialState.modalContent,
    });
  }, [setModalContent]);

  const modalState = useMemo(() => {
    return {
      closeModal,
      setModalContent,
      modalSize: content.modalSize || initialState.modalSize,
      modalContent: content.modalContent || initialState.modalContent,
    } as ModalContextState;
  }, [content, closeModal, setModalContent]);

  return (
    <ModalContext.Provider value={modalState}>
      {children}
      <Modal
        onHide={closeModal}
        size={modalState.modalSize}
        show={Boolean(modalState.modalContent)}
      >
        {modalState.modalContent}
      </Modal>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
