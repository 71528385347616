/* eslint-disable max-len */
import React, { FC, useCallback } from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useAppToastContext } from "@taxscribe/ui";
import { Button } from "react-bootstrap";
import UnauthenticatedWrapper from "../../shared/UnauthenticatedWrapper";

import validate from "./validate";
import config from "../../../config";
import FormikText from "../../shared/FormikText";
import FormikPhone from "../../shared/FormikPhone";
import { useRegistrationContext } from "../../shared/RegistrationProvider";
import { useAuth, useSignin } from "@reasongcp/react-fire-sub";
import { sendEmailVerification } from "firebase/auth";
import { formatFirebasePhoneNumber } from "../../../lib/formatters";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";

const CREATE_ORGANIZATION_AND_ADMIN = gql`
  mutation CreateOrganizationAndAdmin(
    $name: String!
    $email: String!
    $password: String
    $phoneNumber: String
    $displayName: String
  ) {
    createOrganizationAndAdmin(
      name: $name
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      displayName: $displayName
    ) {
      uid
      name
      memberUids
      phone
      state
      city
      streetAddress
      secondaryAddress
      zip
      createdAt
    }
  }
`;

const initialValues = {
  name: "",
  email: "",
  password: "",
  displayName: "",
  phoneNumber: "",
  passwordConfirmation: "",
};

export type FormData = typeof initialValues;
export type FormDataKey = keyof FormData;

const genericError = "An Error Occured Processing Your Request";

const Register: FC = () => {
  const user = useAuth();
  const signin = useSignin();
  const { setEmail } = useRegistrationContext();
  const client = useApolloClientWithAuth(config.fireSubApi);
  const { addToastMessage } = useAppToastContext();
  const navigate = useNavigate();
  const [create] = useMutation(CREATE_ORGANIZATION_AND_ADMIN, { client });

  const toastError = useCallback(
    (body: string) => {
      addToastMessage({
        type: "error",
        header: "Error",
        body: body,
      });
    },
    [addToastMessage],
  );

  const handleSubmit = useCallback(
    async (values: FormData) => {
      // eslint-disable-next-line no-unused-vars
      const { passwordConfirmation, ...rest } = values;
      const { phoneNumber, ...requiredData } = rest;

      try {
        const results = await create({
          variables: {
            ...requiredData,
            phoneNumber: formatFirebasePhoneNumber(phoneNumber),
          },
        });

        if (!results.errors) {
          addToastMessage({
            type: "success",
            header: "Success",
            body: "Organization Successfully Created",
          });
          setEmail(values.email);
          if (!user) {
            const newUser = await signin(values.email, values.password);
            if (newUser) sendEmailVerification(newUser);
          }
          return navigate("/register/verify-email");
        }

        toastError(genericError);
      } catch (e: any) {
        // Adding the duplicate check here on the message as something seems to
        // have changed with how errors are returned from the server. I'm
        // suspicious it has to do with the Apollo version upgrade last year.
        const duplicateEmail = e?.graphQLErrors?.some((err: any) => {
          return (
            err?.extensions?.exception?.errorInfo?.code ===
            "auth/email-already-exists"
          );
        }) || e?.message === "The email address is already in use by another account.";

        if (!duplicateEmail) {
          toastError(genericError);
          return;
        }

        toastError("Account already exists");
        navigate("/reset-password");
      }
    },
    [create, navigate, setEmail, addToastMessage, toastError, user, signin],
  );

  const formHeader = "Welcome to TaxScribePro";
  const formInstructions = "Create your account.";

  return (
    <UnauthenticatedWrapper
      formHeader={formHeader}
      formInstructions={formInstructions}
    >
      <Formik
        validate={validate}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        <Form>
          <FormikText
            label="Your Business or Organization Name"
            placeholder="Organization Name"
            fieldName="name"
            className="mb-3"
          />
          <FormikText
            label="Your Name"
            placeholder="Your Name"
            fieldName="displayName"
            className="mb-3"
          />
          <FormikText
            label="Email Address"
            placeholder="Email Address"
            fieldName="email"
            className="mb-3"
          />
          <FormikText
            type="password"
            label="Password"
            placeholder="Password"
            fieldName="password"
            className="mb-3"
          />
          <FormikText
            type="password"
            label="Confirm Password"
            placeholder="Confirm Password"
            fieldName="passwordConfirmation"
            className="mb-3"
          />
          <FormikPhone
            label="Phone Number (Optional)"
            placeholder="Phone Number"
            fieldName="phoneNumber"
            className="mb-3"
          />
          <Button
            type="submit"
            className="w-100 text-uppercase rounded-1 mt-2 teal-btn"
          >
            Create Account
          </Button>
        </Form>
      </Formik>
    </UnauthenticatedWrapper>
  );
};

export default Register;
