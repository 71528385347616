import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  GET_FORM_MESSAGES,
  GetFormMessagesResponse,
} from "@taxscribe/gql-queries";

import MessageBox from "./MessageBox";
import useFormMessageContext from "../../../hooks/useFormMessageContext";

const SEND_MESSAGE = gql`
  mutation SendMessage($uuid: String!, $detail: String!) {
    createFormMessage(uuid: $uuid, detail: $detail)
  }
`;

const READ_MESSAGES = gql`
  mutation READ_MESSAGES($uuid: String!) {
    readFormMessages(uuid: $uuid)
  }
`;

const emptyMessages: any[] = [];

const useLazyFormMessages = (formUuid?: string | null) => {
  const [query, queryData] = useLazyQuery<GetFormMessagesResponse>(
    GET_FORM_MESSAGES,
    {
      variables: { uuid: formUuid },
    },
  );

  useEffect(() => {
    if (formUuid) query();
  }, [query, formUuid]);
  return queryData;
};

const MessageDrawer: FC = () => {
  const { title, formUuid, closeMessages } = useFormMessageContext();
  const { data } = useLazyFormMessages(formUuid);
  const messages = useMemo(() => {
    const msgs = data?.form?.messages || emptyMessages;
    return [...msgs].reverse();
  }, [data]);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [message, setMessage] = useState<string>("");

  const [readMessagesMutation] = useMutation(READ_MESSAGES, {
    variables: { uuid: formUuid },
    refetchQueries: [
      {
        query: GET_FORM_MESSAGES,
        variables: { uuid: formUuid },
      },
    ],
  });

  const [messageMutation] = useMutation(SEND_MESSAGE, {
    refetchQueries: [
      {
        query: GET_FORM_MESSAGES,
        variables: { uuid: formUuid },
      },
    ],
  });

  const sendMessage = useCallback(() => {
    messageMutation({ variables: { uuid: formUuid, detail: message } })
      .then(() => {
        setMessage("");
      })
      .catch((e: any) => {
        console.error("Error sending message");
        console.error(e);
      });
  }, [messageMutation, formUuid, message, setMessage]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (formUuid) readMessagesMutation();
  }, [formUuid, readMessagesMutation]);

  return (
    <Offcanvas
      placement="end"
      className="bg-light"
      onHide={closeMessages}
      show={Boolean(formUuid)}
    >
      <Offcanvas.Header
        closeButton
        className="border-bottom"
      >
        <Offcanvas.Title>
          <div>Messages:</div>
          <div className="fs-6 fw-light">{title}</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column justify-content-between">
        <div className="overflow-auto">
          {messages.map((message) => (
            <MessageBox
              key={`${message.id}-${message.source}`}
              {...message}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="d-flex flex-row">
          <FloatingLabel
            controlId="floatingTextarea2"
            label="Type a message"
          >
            <Form.Control
              as="textarea"
              placeholder="Type your message"
              style={{ height: "75px", width: "330px" }}
              value={message}
              onChange={handleChange}
              className="border"
            />
          </FloatingLabel>
          <Button
            variant="transparent"
            onClick={sendMessage}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              aria-hidden="true"
              className="text-dark"
              size="lg"
            />
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MessageDrawer;
