/* eslint-disable max-len */
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable max-len */

export type DataType = Record<string, any>;

export const validateEmail = (values: DataType, key = "email") => {
  const valid = String(values[key] || "")
    .toLowerCase()
    .match(emailRegex);
  if (!valid) return { [key]: "Invalid Email" };
  return {};
};

export const checkPresence = (key: string) => (values: DataType) => {
  if (!values[key]) return { [key]: "* Required" };
  return {};
};
