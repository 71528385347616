import { gql, useMutation } from "@apollo/client";
import { GET_MY_NOTIFICATIONS } from "./useMyNotificationsLazy";

export const READ_MY_NOTIFICATIONS = gql`
  mutation ReadMyNotifications($ids: [Int]!) {
    markTaxpayerNotificationsViewed(ids: $ids)
  }
`;

const useReadMyNotifications = () => {
  return useMutation(
    READ_MY_NOTIFICATIONS,
    { refetchQueries: [GET_MY_NOTIFICATIONS] },
  );
};

export default useReadMyNotifications;
