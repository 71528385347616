import { camelCase } from "lodash";
import { config as firebaseQa } from "./qa.firebaseConfig";
import { config as firebaseUat } from "./uat.firebaseConfig";
import { config as firebaseProduction } from "./production.firebaseConfig";

const appEmail = "no-reply@taxscribe.app";

/* eslint no-useless-escape: 0 */
// const previewRegExp =
//   /([^ ]*taxscribe-qa-bpp-app--pr[0-9]{1,3}\-.+?\.\web\.app)/;
//
const hostname = window.location.hostname;
const getCookieName = (): string => {
  const prefix = "pro_auth";
  const domain = hostname.split(".").slice(1).join("_");

  return camelCase(`${prefix}_${domain}`);
};

export type KnownStage = "qa" | "uat" | "development" | "production";
const detectStage = (): KnownStage => {
  const { hostname } = window.location;

  if (hostname === "pro.taxscribe.app") {
    return "production";
  }

  if (hostname === "qa-pro.taxscribe.app") {
    return "qa";
  }

  if (hostname === "uat-pro.taxscribe.app") {
    return "uat";
  }

  // if (hostname.match(previewRegExp)) {
  //   return "preview";
  // }

  return "development";
};

const configOpts = {
  production: {
    appEmail,
    // eslint-disable-next-line
    stripePk: "pk_live_51PsoCsGz9c8JXc2DTVjDsYGnif6VF0rhnr6P3FqByFMrS2LFdXgpY7iyLQJOKSshWf2seDMPfvmpJN2y1MTa02Fw00NKWlTixt",
    fireSubApi: "https://sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseProduction,
    authCookieName: getCookieName(),
    taxbaseUri: "https://taxbase.taxscribe.app/",
    bppUri: "https://bpp.taxscribe.app/",
    marketingUri: "https://www.taxscribe.com/",
  },
  uat: {
    appEmail,
    // eslint-disable-next-line
    stripePk: "pk_test_51PsoCsGz9c8JXc2DbC1TbM6rqCvdfrYj2K8MAJ5mJi3vnuYwqVD7FizJoqG5Eo2ti8D8Ops1ZLyupRYuWRqy1erm00pWujQ3Xl",
    fireSubApi: "https://uat-sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseUat,
    authCookieName: getCookieName(),
    taxbaseUri: "https://uat-taxbase.taxscribe.app/",
    bppUri: "https://uat-bpp.taxscribe.app/",
    marketingUri: "https://uat-www.taxscribe.com/",
  },
  qa: {
    appEmail,
    // eslint-disable-next-line
    stripePk: "pk_test_51PsoCsGz9c8JXc2DbC1TbM6rqCvdfrYj2K8MAJ5mJi3vnuYwqVD7FizJoqG5Eo2ti8D8Ops1ZLyupRYuWRqy1erm00pWujQ3Xl",
    fireSubApi: "https://qa-sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseQa,
    authCookieName: getCookieName(),
    taxbaseUri: "https://qa-taxbase.taxscribe.app/",
    bppUri: "https://qa-bpp.taxscribe.app/",
    marketingUri: "https://qa-www.taxscribe.com/",
  },
  development: {
    appEmail,
    firebaseConfig: {
      ...firebaseQa,
      projectId: "taxscribe-dev",
    },
    // eslint-disable-next-line
    stripePk: "pk_test_51PsoCsGz9c8JXc2DbC1TbM6rqCvdfrYj2K8MAJ5mJi3vnuYwqVD7FizJoqG5Eo2ti8D8Ops1ZLyupRYuWRqy1erm00pWujQ3Xl",
    fireSubApi: "http://localhost:4001/",
    authCookieName: getCookieName(),
    taxbaseUri: "http://localhost:4002/",
    bppUri: "http://localhost:6007",
    marketingUri: "http://localhost:8081/",
  },
};

const releaseStage = detectStage();
const devStages = ["uat", "qa", "preview", "development"];
const devMode = devStages.indexOf(releaseStage) >= 0;
const config = { ...configOpts[releaseStage], releaseStage, devMode };

export default config;
