/* eslint-disable max-len */
import { FC, PropsWithChildren } from "react";
import { Container, Row, Col } from "react-bootstrap";

import logo from "../../images/taxscribe-pro-logo.png";
import TSForm from "../../images/form.png";

interface FormHeaderProps {
  formInstructions?: string;
  formHeader?: string;
}

const UnauthenticatedWrapper: FC<PropsWithChildren<FormHeaderProps>> = ({
  children,
  formInstructions,
  formHeader,
}: any) => {
  return (
    <main>
      <Container
        fluid
        className="p-0"
      >
        <Row className="g-0 min-vh-100">
          <Col
            md={6}
            className="p-2"
          >
            <div className="mb-3 mb-lg-4">
              <img
                alt="taxscribe-pro-logo"
                src={logo}
                width="120"
                height="auto"
              />
            </div>
            <div className="form-container">
              <Row className="justify-content-center p-2 p-lg-4">
                <Col lg={8}>
                  <h1 className="h2">{formHeader}</h1>
                  <p>
                    <small>{formInstructions}</small>
                  </p>
                  {children}
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            md={6}
            className="d-none d-md-flex align-items-center justify-content-center bg-pro-grey"
          >
            <div className="p-4">
              {" "}
              <img
                alt="taxscribe-pro-logo"
                src={TSForm}
                width="650"
                height="auto"
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default UnauthenticatedWrapper;
