import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { useAuth, useSUBContext } from "@reasongcp/react-fire-sub";

// Possible upstream update.
const useObserveUserDoc = () => {
  const { firestore } = useSUBContext();
  const user = useAuth();
  const userId = user?.uid;
  const [userDoc, setUserDoc] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    if (!userId || !firestore) return;
    onSnapshot(doc(firestore, "users", userId), (document) => {
      setUserDoc(document?.data() || null);
    });
  }, [setUserDoc, userId, firestore]);

  return userDoc;
};

export default useObserveUserDoc;
