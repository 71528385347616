import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useObserveDocumentAttribute } from "@taxscribe/ui";
import {
  useAuth,
  useCurrentOrgMembers,
  useUserDoc,
} from "@reasongcp/react-fire-sub";

import AppNavbar from "./AppNavbar";
import SectionContent from "./SectionContent";
import useCreditCount from "../../hooks/useCreditCount";
import AvailableCreditsHeader from "./AvailableCreditsHeader";
import LoadingPlaceholder from "./AppRoutes/LoadingPlaceholder";

const StyledLink = styled(Link)`
  color: #334171;
`;

const NoCreditsWarning: FC<{ node?: React.ReactNode }> = () => {
  const user = useAuth();
  const members = useCurrentOrgMembers();
  const memberDoc = members.find((member) => (member.uid = user?.uid));
  const isAdmin = memberDoc?.roles.includes("admin");

  if (!isAdmin) {
    return (
      <>
        <AppNavbar pageTitle="Insufficient Credits">
          <AvailableCreditsHeader />
        </AppNavbar>
        <SectionContent>
          <Alert variant="warning">
            Your organization does not have sufficient credits to complete this
            transaction. Please contact your organization administrator to
            purchase more credits.
          </Alert>
        </SectionContent>
      </>
    );
  }

  return (
    <>
      <AppNavbar pageTitle="Insufficient Credits">
        <AvailableCreditsHeader />
      </AppNavbar>
      <SectionContent>
        <Alert variant="warning">
          Your organization does not have sufficient credits to complete this
          transaction. You can purchase credits at the{" "}
          <StyledLink
            to="/billing"
            className="text-decoration-none stretched-link"
          >
            billing page.
          </StyledLink>
        </Alert>
      </SectionContent>
    </>
  );
};

const CreditBoundary: FC<PropsWithChildren> = ({ children }) => {
  const { called, loading, available } = useCreditCount();
  const userDoc = useUserDoc();
  const [autoPurchase] = useObserveDocumentAttribute<boolean>({
    defaultValue: false,
    attributeKey: "autoPurchase",
    documentPath: `organizations/${userDoc?.currentOrganization}`,
  });

  if (loading || !called) {
    return <LoadingPlaceholder />;
  }

  const numberAvailable = Number(available);

  if ((isNaN(numberAvailable) || numberAvailable < 1) && !autoPurchase) {
    return <NoCreditsWarning />;
  }

  return <>{children}</>;
};

export default CreditBoundary;
