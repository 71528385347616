import { useCallback, useContext } from "react";

// eslint-disable-next-line max-len
import MessageDrawerContext from "../components/shared/MessageDrawer/MessageDrawerContext";

const useFormMessageContext = () => {
  const { title, formUuid, viewFormMessages } =
    useContext(MessageDrawerContext);

  const closeMessages = useCallback(() => {
    viewFormMessages({ title: null, formUuid: null });
  }, [viewFormMessages]);

  const openMessages = useCallback(
    (formUuid: string) => {
      viewFormMessages({
        formUuid,
        title: "Messages",
      });
    },
    [viewFormMessages],
  );

  return {
    title,
    formUuid,
    openMessages,
    closeMessages,
  };
};

export default useFormMessageContext;
