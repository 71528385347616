import { FC } from "react";
import { Alert } from "react-bootstrap";

const MessageBox: FC<any> = (message) => {
  const { contactName, detail, createdAt, source } = message;
  const date = new Date(Number(createdAt)).toLocaleString("en-US");

  return (
    <>
      {source === "BizLink" ? (
        <div className="text-end mb-1">
          <span className="text-black-50 p-2">{date}</span>
          <strong>{contactName}</strong>
        </div>
      ) : (
        <div className="text-start mb-1">
          <strong className="p-2">{contactName}</strong>
          <span className="text-black-50">{date}</span>
        </div>
      )}
      <Alert
        variant={source === "BizLink" ? "primary" : "info"}
        className="text-dark"
      >
        {detail}
      </Alert>
    </>
  );
};

/* eslint-enable camelcase */
export default MessageBox;
