import React, { FC, ReactNode } from "react";
import { Form } from "react-bootstrap";
import { FieldInputProps, FieldMetaProps, useField } from "formik";

export interface FormikTextAsProps {
  rows?: number;
  type?: string;
  fieldName: string;
  placeholder: string;
  isInvalid: boolean;
}

export interface FormikTextProps extends Omit<FormikTextAsProps, "isInvalid"> {
  rows?: number;
  as?: FC<FormikTextAsProps>;
  label: ReactNode;
  disabled?: boolean;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

type FieldProps = string | number | string[] | undefined;

interface FormControlProps {
  rows?: number;
  as?: FC<FormikTextAsProps>;
  type?: string;
  fieldName: string;
  disabled?: boolean;
  placeholder: string;
  meta: FieldMetaProps<FieldProps>;
  field: FieldInputProps<FieldProps>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormControl: FC<FormControlProps> = ({
  as,
  meta,
  type,
  rows,
  field,
  disabled,
  onChange,
  fieldName,
  placeholder,
}) => {
  if (as) {
    return (
      <Form.Control
        {...field}
        as={as}
        rows={rows}
        type={type}
        disabled={disabled}
        fieldName={fieldName}
        placeholder={placeholder}
        onChange={onChange || field.onChange}
        isInvalid={Boolean(meta.touched && meta.error)}
      />
    );
  }

  return (
    <Form.Control
      {...field}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange || field.onChange}
      isInvalid={Boolean(meta.touched && meta.error)}
    />
  );
};

const FormikText: FC<FormikTextProps> = ({
  as,
  type,
  rows,
  label,
  fieldName,
  placeholder,
  className,
  disabled,
  onChange,
}) => {
  const [field, meta] = useField(fieldName);

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>
      <FormControl
        as={as}
        rows={rows}
        meta={meta}
        type={type}
        field={field}
        disabled={disabled}
        onChange={onChange}
        fieldName={fieldName}
        placeholder={placeholder}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormikText;
